import '@angular/localize/init';
import { GroupSource } from '@nl/email/app/module/campaign/campaign-composer/state/campaign-composer.model';
import { isNil, zipObject } from 'lodash-es';
import { getOr } from 'lodash/fp';

export enum SubscriberType {
  SUBSCRIBED,
  HARD_BOUNCE,
  SOFT_BOUNCE,
  COMPLAINED,
  UNSUBSCRIBED,
  UNSUBSCRIBED_BY_OWNER,
}

export const subscriberTypes = [
  SubscriberType.SUBSCRIBED,
  SubscriberType.HARD_BOUNCE,
  SubscriberType.SOFT_BOUNCE,
  SubscriberType.COMPLAINED,
  SubscriberType.UNSUBSCRIBED,
  SubscriberType.UNSUBSCRIBED_BY_OWNER,
];
const subscriberLabels = zipObject(subscriberTypes, [
  $localize`正常`,
  $localize`永久退信`,
  $localize`暫時退信`,
  $localize`抱怨`,
  $localize`取消訂閱`,
  $localize`手動排除`,
]);
const dateLabels = zipObject(subscriberTypes, [
  $localize`更新日期`,
  $localize`退信日期`,
  $localize`退信日期`,
  $localize`抱怨日期`,
  $localize`取消日期`,
  $localize`建立日期`,
]);

export const getSubscriberLabel = (type: SubscriberType, count?: number) =>
  isNil(count)
    ? subscriberLabels[type]
    : `${subscriberLabels[type]} (${count.toLocaleString('en')})`;

export const getDateLabelForSubscriberType = (type: SubscriberType) =>
  getOr($localize`日期`, type, dateLabels);
export const NAME_KEY = 'name';
export const DATE_KEY = 'date';

export interface SubscribersMember {
  email: string;
  name: string;
  parameter: object;
  date: string;
  sn: string;
  type: SubscriberType;
  groups: Array<string>;
}

export interface UpdateMemberRequest {
  email: string;
  name: string;
  parameter: object;
  belongGroups: Array<string>;
}

export interface List extends GroupSource {
  clickedRate: number;
  createDate: string;
  excludeCnt: number;
  name: string;
  openedRate: number;
  segVersion: number;
  status: number;
  type: number;
  subscribedCnt: number;
}

export enum ListType {
  FIXED = 0,
  DYNAMIC = 1,
  ALL = 2,
}

export const enum ContactColumns {
  USER_SN,
  MAIL_HASH,
  MAIL_ADDRESS,
  DOMAIN,
  NAME,
  UPDATE_DATE,
  STATUS,
  DELIVERED_COUNT,
  OPENED_COUNT,
  CLICKED_COUNT,
  OPEN_RATE,
  CLICK_RATE,
  OPENED_TAG,
  CLICKED_TAG,
  DELIVERED_TAG,
  EMAIL_TAG,
  FIXED_GROUP,
  DYNAMIC_GROUP,
  PARAMS,
  HAD_SENT,
  HAD_DELIVERED,
  HAD_OPENED,
  HAD_CLICKED,
  BOUNCED_DATE,
  UNSUBSCRIBED_CAMPAIGN,
  UNSUBSCRIBED_REASON,
  UNSUBSCRIBED_DATE,
  COMPLAINED_CAMPAIGN,
  COMPLAINED_DATE,
  EXCLUDED_DATE,
  CAMPAIGN_BEHAVIOR,
  CAMPAIGN,
}

export const enum Operators {
  EQ,
  GT,
  GTE,
  LT,
  LTE,
  LIKE,
  NOT_LIKE,
  IN,
  IS_NULL,
  IS_NOT,
  NOT_EQ,
  START_WITH,
  END_WITH,
}

export const enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ContactColumnRequest {
  type: ContactColumns;
  name?: string;
  operators?: Operators;
  value?: any;
  direction?: Direction;
}

export interface FilterContactsRequest {
  columns: ContactColumnRequest[];
  restrictions: ContactColumnRequest[];
  criteriaBlocks: ContactColumnRequest[][];
  orderBy?: ContactColumnRequest;
}

export interface MatchContacts {
  total: number;
  contacts: any[];
}

type restrictionKey = 'type' | 'keyword' | 'fixedGroup' | 'dynamicGroup';

export interface FilterContactsForm {
  params: string[];
  // @ts-ignore
  restrictions: { [key: restrictionKey]: any };
  criteriaBlocks?: ContactColumnRequest[][];
  pagination?: {
    page: number;
    size: number;
  };
}

export interface MultipleDeleteResponse {
  count: number;
  warningList: Array<ListsWithCampaignStatus>;
}

export interface ListsWithCampaignStatus {
  sn: string;
  name: string;
  listCampaignInfo: Array<ListCampaignInfo>;
}

export interface ListCampaignInfo {
  name: string;
  status: string;
}

export enum ListImportType {
  FILE = 0,
  CSV = 1,
  SINGLE = 2,
  FILTER = 3,
  DELETE = 4,
}

export enum ImportType {
  FILE = 'file',
  PLAIN_TEXT = 'plaintext',
  FORM = 'form',
}
